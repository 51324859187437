
@import './react-datepicker.css';
@import './font-awesome.css';
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

body {
   font-family: 'Open Sans',serif;
}

.container-fluid {
  background: #f6f9fc;
}

.popover {
  max-width: none;
}

.italic {
  font-style: italic;
}

.title {
  font-size: 20px;
}

.sub-title {
  font-size: 12px;
}

.modal-dialog {
  pointer-events: inherit;
}

.modal-lg {
  max-width: 1200px;
}

.home-content {
  margin-top: 100px; 
  padding-left: 35px;
  padding-right: 35px;
}

.login-footer {
  margin-top: 20px;
  text-align: center;
}

.broker-login-button {
  width: 180px;
  margin-bottom: 5px;
}

.nav-tabs .nav-item {
  cursor: pointer;
}

.tab-content {
  background: #fff;
  margin-top: 10px;
}

.number-right {
  text-align: right;
}

.number-pos {
  color: green;
}

.number-neg {
  color: red;
}

.trades-history-input {
  margin-bottom: 10px;
}

.date-picker-wrapper {
  margin-right: 10px;
  display: inline-block;
}

.date-picker-wrapper label {
  margin-right: 5px;
}

.date-picker-submit {
  display: inline-block;
}

.input-group {
  margin-right: 10px;
  display: inline-block;
}

.input-group label {
  margin-right: 5px;
}

.filter-inputs {
  float: right;
  margin-right: 10px;
  line-height: 20px;
}

.filter-inputs .filter-input {
  display: inline-block;
  margin-left: 10px;
}

.filter-inputs .filter-input select {
  display: inline-block;
  width: 120px;  
}

.filter-inputs .filter-input input[type=checkbox] {
  vertical-align: middle;
}

.filter-inputs .filter-input label {
  margin-right: 5px;
}

.error-text {
  color: red;
}

.status-panel {
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-panel .title {
  display: inline-block;
}

.header-panel .refresh-status {
  font-weight: 600;
  display: inline-block;
  float: right;
  text-align: right;
}

.notes-alert {
  font-size: 15px;
  float: right;
  text-align: right;
}

.bottom-notes {
  padding: 20px;
  font-size: 15px;
}

.table td {
  vertical-align: middle;
}

.dropdown-item:hover {
  cursor: pointer;
}

.font-white {
  color: white;
}

.font-black {
  color: black;
}

.font-blue {
  color: blue;
}

.row-color-odd {
  background: #f6f6f6;
}

.row-color-even {
  background: #dae5f4;
}

.row-separator {
  border-top: 2px solid #59989d;
}

@media (max-width: 767px) {
  .profile-mobile .nav-item {
    display: inline-block;
  }
}

#multidayselect {
  margin-top: 10px;
  width: 100%;
}

.reports-button-group {
  margin-right: 10px;
}

.card {
  margin-bottom: 20px;
  border: 1px solid #d4d9df;
  clear: both;
}

.trades-main-comp > .nav > .nav-item > .nav-link {
  font-weight: bold;
}